:root{
  /* light mode */
  --information: #4D6CB6;
  --error: #B73F30;
  --validation: #3F8A63;
  --warning: #E9730D;
  --warning-background: #FFF8EB;

  --toast-background:  var(--white);
  --toast-box-shadow: 23px 5px 10px rgba(92, 95, 102, 0.01), 13px 3px 8px rgba(92, 95, 102, 0.03), 6px 1px 6px rgba(92, 95, 102, 0.04), 1px 0px 3px rgba(92, 95, 102, 0.05), 0px 0px 0px rgba(92, 95, 102, 0.05);
  --default-toast-background: var(--black);
  --default-toast-message-color: var(--light-grey-2);

  --warning-toast-background: var(--white);
  --warning-toast-message-color: var(--warning);
  --warning-toast-icon-color: var(--black);

  --background-color: var(--white);
  --background-color-2: var(--white);
  --background-color-3: var(--white);
  --background-color-4: var(--light-grey-2);
  --font-color: var(--black);
  --font-color-2: var(--faded-black-text);
  --font-color-3: var(--dark-grey);
  --helper-color: var(--light-grey-2);

  --header-background-color: var(--secondary-color-1-20);
  --header-primary-color: var(--secondary-color-1-30B);
  --header-segmnet-button: var(--contrast-color);

  --home-button-shadow: -2px -22px 9px rgba(163, 163, 163, 0.01), -1px -13px 8px rgba(163, 163, 163, 0.05), -1px -6px 6px rgba(163, 163, 163, 0.09), 0px -1px 3px rgba(163, 163, 163, 0.1), 0px 0px 0px rgba(163, 163, 163, 0.1);
  --footer-background-color: var(--off-white);
  --footer-badge-font-color: var(--white);
  --footer-badge-background-color: var(--black);
  --footer-box-shadow: -2px -22px 9px rgba(163, 163, 163, 0.01), -1px -13px 8px rgba(163, 163, 163, 0.05), -1px -6px 6px rgba(163, 163, 163, 0.09), 0px -1px 3px rgba(163, 163, 163, 0.1), 0px 0px 0px rgba(163, 163, 163, 0.1);
  --footer-button-color: var(--faded-gray);
  --footer-button-selected-color: var(--primary-color-1);

  --break-line-color: var(--light-grey-2);
  --border-visibility-color: var(--light-grey-2);
  --search-border-color: var(--grey);
  --search-list-break-line-color: var(--grey);
  --selected-list-item-background: var(--off-white);

  --card-background: var(--white);
  --card-secondary-background: var(--off-white);
  --sliding-card-background: var(--white);
  --sliding-card-secondary-background: var(--light-grey-2);
  --card-title-color: var(--black);
  --card-box-shadow: -16px 20px 10px rgba(199, 188, 188, 0.01), -8px 12px 8px rgba(199, 188, 188, 0.05), -4px 4px 8px rgba(199, 188, 188, 0.09), -4px 4px 4px rgba(199, 188, 188, 0.1), 0px 0px 0px rgba(199, 188, 188, 0.1);

  --calendar-range-color: var(--light-grey-2);
  --date-picker-segment-background-color: var(--secondary-color-1-20);
  --date-picker-header-color: var(--dark-grey);
  --date-picker-cell-color: var(--dark-grey);
  --date-picker-disabled-cell-color: var(--grey);
  --time-picker-button-border-color: var(--black);
  /* time picker highlight */
  --ion-color-step-150: var(--light-grey-2);

  --date-button-background-color: var(--light-grey-2);
  --date-button-selected-background-color: var(--grey);

  --skeleton-color: 41, 47, 48;

  --activity-chip-background: var(--secondary-color-2-20);

  --input-background-color: var(--background-color-3);
  --input-font-color: var(--font-color);
  --input-border-focus-color: var(--black);
  --input-border-color: var(--faded-black-text);

  --toggle-on: var(--white);
  --toggle-on-background: var(--black);
  --toggle-off: var(--white);
  --toggle-off-background: var(--disabled-white-color);
  --toggle-disabled: #F3F3F3;
  --toggle-disabled-background: var(--disabled-white-color);

  --language-chip-background: var(--secondary-color-1-30B);
  --version-color: #C0C2C8;

  --visa-card-box-shadow:  0 30px 50px -40px var(--secondary-color-1-30B);
  --mastercard-box-shadow: 0 30px 50px -40px var(--black);
  --payment-card-slider-color: #E3E5EC;
  --selected-payment-card-slider-color: #545969;
}
/* dark mode */
:root[color-theme="dark"]{
  --primary-color-1: var(--primary-color-dark) !important;
  --secondary-color-1-30B: var(--secondary-color-dark-30B) !important;
  --secondary-color-1-50: var(--secondary-color-dark-50) !important;
  --secondary-color-1-20: var(--secondary-color-dark-20) !important;

  --information: #7AA1FF;
  --error: #FB9F93;
  --validation: #97DFB9;
  --warning: #FFAE67;

  --toast-background:  var(--dark-2-800);
  --toast-box-shadow: none;
  --default-toast-background: var(--dark-2-800);
  --default-toast-title-color: var(--white);
  --default-toast-message-color: var(--light-grey-2);

  --warning-toast-background: var(--dark-2-800);
  --warning-toast-message-color: var(--warning);
  --warning-toast-icon-color: var(--dark-200);

  --background-color: var(--dark-900);
  --background-color-2: var(--dark-3-900);
  --background-color-3: var(--dark-800);
  --background-color-4: var(--dark-2-900);
  --font-color: var(--dark-200);
  --font-color-2: var(--dark-400);
  --font-color-3: var(--dark-400);
  --helper-color: var(--dark-2-800);

  --header-background-color: var(--dark-800);
  --header-primary-color: var(--primary-color-1);
  --header-segmnet-button: var(--background-color);

  --home-button-shadow: none;
  --footer-background-color: var(--dark-800);
  --footer-badge-font-color: var(--dark-900);
  --footer-badge-background-color: var(--dark-200);
  --footer-box-shadow: none;
  --footer-button-color: var(--dark-400);
  --footer-button-selected-color: var(--primary-color-1);

  --break-line-color: var(--dark-2-900);
  --border-visibility-color: transparent;
  --search-border-color: transparent;
  --search-list-break-line-color: var(--dark-2-800);
  --selected-list-item-background: var(--dark-800);

  --card-background: var(--dark-3-900);
  --card-secondary-background: var(--dark-3-900);
  --card-title-color: var(--dark-200);
  --card-box-shadow: none;
  --sliding-card-background: var(--dark-2-900);
  --sliding-card-secondary-background: var(--dark-2-900);

  --calendar-range-color: var(--dark-2-900);
  --date-picker-segment-background-color: var(--dark-800);
  --date-picker-header-color: var(--dark-500);
  --date-picker-cell-color: var(--dark-300);
  --date-picker-disabled-cell-color: var(--dark-2-500);
  --time-picker-button-border-color: transparent;
  /* time picker highlight */
  --ion-color-step-150: var(--dark-2-900);

  --date-button-background-color: var(--dark-2-900);
  --date-button-selected-background-color: var(--dark-2-800);

  --skeleton-color: 255,255,255;

  --activity-chip-background: var(--dark-2-900);

  --input-background-color: var(--background-color-3);
  --input-font-color: var(--font-color);
  --input-border-focus-color: transparent;
  --input-border-color: transparent;

  --toggle-on: var(--white);
  --toggle-on-background: var(--grey);
  --toggle-off: var(--white);
  --toggle-off-background: var(--dark-700);
  --toggle-disabled: var(--dark-2-500);
  --toggle-disabled-background: var(--dark-700);

  --language-chip-background: var(--primary-color-1);
  --version-color: #C0C2C8;

  --visa-card-box-shadow:  none;
  --mastercard-box-shadow: none;
  --payment-card-slider-color: #545969;
  --selected-payment-card-slider-color: #E3E5EC;
}
