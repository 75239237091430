ion-segment {
  --background: transparent;
  height: 40px;

  &::-webkit-scrollbar {
    display: none;
  }
  ion-segment-button {
    --border-style: none;
    --indicator-color: var(--primary-color-1);
    --border-radius: 30px;
    ion-label {
      padding: 0px 12px 0px 12px;
      font-size: 16px;
    }
    //font color
    --color: var(--contrast-color);
    --color-checked: var(--header-segmnet-button);

  }
}
