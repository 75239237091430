// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** non-transparent lightened secondary colors **/
$secondary-30B-20: lighten(#32659f, 50%);
$secondary-dark-30B-20: lighten(#1a87e0, 50%);

/** Ionic CSS Variables **/
:root {
  --primary-color-1: #6093cd;
  --primary-color-2: #afcb08;
  --primary-color-dark: #78b9ef;

  --secondary-color-1-30B: #32659f;
  --secondary-color-1-50: #afc9e6;
  --secondary-color-1-20: #dfe9f5;
  /** non-transparent version of above **/
  --secondary-color-1-20-opaque: #{$secondary-30B-20};

  --secondary-color-2-50: #d7e583;
  --secondary-color-2-20: #eff5ce;

  --secondary-color-dark-30B: #1a87e0;
  --secondary-color-dark-50: #bbdcf7;
  --secondary-color-dark-20: #e4f1fc;
  /** non-transparent version of above **/
  --secondary-color-dark-20-opaque: #{$secondary-dark-30B-20};

  --white: #ffffff;
  --off-white: #f9f9fb;
  --light-grey: #efefef;
  --light-grey-2: #edeff4;
  --light-grey-2-rgb: 41, 47, 48;
  --disabled-white-color: #e9e9ea;
  --grey: #d2d5da;
  --light-text: #9f9c9c;
  --faded-black-text: #7f828f;
  --faded-gray: #9ea2b5;
  --dark-grey: #5f5b5b;
  --black: #292f44;
  --dark-900: #121212;
  --dark-3-900: #222222;
  --dark-2-900: #313131;
  --dark-800: #353535;
  --dark-700: #4d4d4d;
  --dark-2-800: #515151;
  --dark-2-500: #8f8f8f;
  --dark-500: #a6a6a6;
  --dark-2-400: #afafaf;
  --dark-400: #bebebe;
  --dark-300: #d9d9d9;
  --dark-200: #eaeaea;
  --transparent-white: rgba(255, 255, 255, 0.5);

  --ion-font-family: 'Montserrat', sans-serif;
  --icon-color: #5f5b5b;

  --ion-background-color: var(--background-color);
  --toolbar-height-multiple: 52px;
  --toolbar-height-single: 80px;

  /** primary **/
  --ion-color-primary: var(--primary-color-1);
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: red;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

.ion-color-time-picker {
  --ion-color-base: var(--white);
  --ion-color-base-rgb: var(--white);
  --ion-color-contrast: var(--white);
  --ion-color-contrast-rgb: var(--white);
  --ion-color-shade: var(--white);
  --ion-color-tint: var(--white);
}

@import url('color-theme.scss');
