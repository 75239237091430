ion-toast.toast-class {
  --background: var(--default-toast-background);
  --color: var(--default-toast-title-color);
}

ion-toast.toast-class::part(container){
  min-height: 60px;
}

ion-toast.toast-class::part(message) {
  font-weight: 400;
  font-size: 12px;
  color: var(--default-toast-message-color);
}

ion-toast.toast-class::part(header) {
  font-weight: 600;
  font-size: 14px;
}

ion-toast.toast-class::part(button) {
  color: var(--default-toast-message-color);
  font-size: 14px;
  font-weight: 500;
  padding-inline: 12px;
}

.success-toast{
  --background: var(--toast-background);
  --color: var(--validation);

  &::part(message){
    font-weight: 600;
    font-size: 12px;
  }
  &::part(container){

    box-shadow: var(--toast-box-shadow);
    border-radius: 15px;
  }

  &::part(button){
    color: var(--font-color);
  }
}

.connection-status-toast {
  --height: 60px;
  --background: var(--default-toast-background);
  --color: var(--default-toast-title-color);

  &::part(message){
    font-weight: 600;
    font-size: 14px;
    color: var(--default-toast-title-color);
  }

  &::part(button){
    color: var(--default-toast-message-color);
  }
}

.warning-toast {
  --height: 60px;
  --background: var(--warning-toast-background);
  --color: var(--warning-toast-message-color);

  &::part(message){
    font-weight: 600;
    font-size: 12px;
    color: var(--warning-toast-message-color);
  }

  &::part(button){
    color: var(--warning-toast-icon-color);
  }
}
