ion-item {
  .form-input {
    @extend .form-input-no-border;
    input {
      &.native-input.sc-ion-input-ios {
        border: 1px solid var(--input-border-color);
        border-radius: 50px;
        &:focus-visible {
          border: 1px solid var(--input-border-focus-color);
        }
        &.invalid {
          border: 1px solid var(--error);
        }
      }
    }
  }
  .form-input-error {
    @extend .form-input;
    input {
      &.native-input.sc-ion-input-ios {
        border: 1px solid var(--error);
        &:focus-visible {
          border: 1px solid var(--error);
        }
      }
    }
  }
  .form-input-no-border {
    input {
      &.native-input.sc-ion-input-ios {
        padding-left: 24px;
        font-weight: 400;
        font-size: 16px;
        color: var(--input-font-color);
        &::placeholder {
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
  }
}
