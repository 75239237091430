:root[color-theme="dark"]{
  .button-primary{
    --color: var(--background-color);
    --color-focused: var(--background-color);
    --color-activated: var(--background-color);
    --background-focused: var(--secondary-color-1-30B);
    --background-activated: var(--secondary-color-1-30B);
    --border-color: transparent;

    &[disabled]{
      --color: var(--dark-2-500);
      --background: var(--dark-800);
      --border-color: transparent;
    }
  }

  .button-secondary{
    --background: var(--background-color);
    --background-hover: var(--background-color);
    --background-focused: var(--dark-800);
    --background-activated: var(--dark-800);

    &[disabled]{
      --color: var(--dark-2-500);
      --background: var(--dark-800);
      --border-color: var(--dark-800);
    }

    --added-button-color: var(--primary-color-1);
  }
}

.button-primary{
  font-weight: 600;
  --background: var(--primary-color-1);
  --background-hover: var(--primary-color-1);
  --background-focused: var(--secondary-color-1-30B);
  --background-activated: var(--secondary-color-1-30B);
  --border-radius: 100px;
  height: 44px;
  //font color
  --color: var(--contrast-color);
  --color-hover: var(--contrast-color);
  --color-focused: var(--contrast-color);
  --color-activated: var(--contrast-color);

}

.button-secondary{
  // must add property "fill: outline" to the button in order for the border to show up
  font-weight: 600;
  --background: var(--white);
  --background-hover: var(--white);
  --background-focused: var(--secondary-color-1-20);
  --background-activated: var(--secondary-color-1-20);
  --color: var(--primary-color-1);
  --color-hover: var(--primary-color-1);
  --color-focused: var(--primary-color-1);
  --color-activated: var(--primary-color-1);
  --border-color: var(--primary-color-1);
  --border-width: 2px;
  --border-radius: 100px;
  height: 44px;
  --added-button-color: var(--secondary-color-1-30B);
}

.button-no-fill {
  // must add property "fill: clear" to the button in order for the border to show up
  --color: var(--font-color);
}
