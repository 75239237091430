@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(Montserrat-Regular.woff) format('woff2');
  }

@font-face {
  font-family: 'Montserrat';
  font-style:normal;
  font-weight: 500;
  src: url(Montserrat-Medium.woff) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url(Montserrat-SemiBold.woff) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url(Montserrat-Bold.woff) format('woff2');
}
