@font-face {
  font-family: "icomoon";
  src: url("icomoon.eot?f2hacu");
  src: url("icomoon.eot?f2hacu#iefix") format("embedded-opentype"),
    url("icomoon.ttf?f2hacu") format("truetype"),
    url("icomoon.woff?f2hacu") format("woff"),
    url("icomoon.svg?f2hacu#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}

.icon-bi_question-circle-fill:before {
  content: "\e935";
  color: #8cc2ff;
}
.icon-gs-icon-dark-mode:before {
  content: "\e934";
}
.icon-gs-icon-terms-and-conditions:before {
  content: "\e933";
}
.icon-gs-icon-remove:before {
  content: "\e92e";
}
.icon-gs-icon-add:before {
  content: "\e92f";
}
.icon-gs-icon-edit:before {
  content: "\e930";
}
.icon-gs-icon-guest:before {
  content: "\e931";
}
.icon-gs-icon-information:before {
  content: "\e932";
}
.icon-gs-icon-left:before {
  content: "\e92a";
}
.icon-gs-icon-right:before {
  content: "\e92b";
}
.icon-gs-icon-communication:before {
  content: "\e928";
}
.icon-gs-icon-language:before {
  content: "\e929";
}
.icon-gs-icon-question-mark:before {
  content: "\e927";
}
.icon-gs-icon-applepay:before {
  content: "\e924";
}
.icon-gs-icon-googlepay:before {
  content: "\e925";
}
.icon-gs-icon-visa1:before {
  content: "\e91d";
  color: #fff;
}
.icon-gs-icon-mastercard1:before {
  content: "\e92c";
}
.icon-gs-icon-myaccount:before {
  content: "\e92d";
}
.icon-gs-icon-check-stroke:before {
  content: "\e923";
}
.icon-gs-icon-activity:before {
  content: "\e900";
}
.icon-gs-icon-add-filled:before {
  content: "\e901";
}
.icon-gs-icon-add1:before {
  content: "\e902";
}
.icon-gs-icon-arrow:before {
  content: "\e903";
}
.icon-gs-icon-basket:before {
  content: "\e904";
}
.icon-gs-icon-calendar:before {
  content: "\e905";
}
.icon-gs-icon-card:before {
  content: "\e906";
}
.icon-gs-icon-check:before {
  content: "\e907";
}
.icon-gs-icon-close:before {
  content: "\e908";
}
.icon-gs-icon-current-location:before {
  content: "\e909";
}
.icon-gs-icon-cvv:before {
  content: "\e90a";
}
.icon-gs-icon-delete:before {
  content: "\e90b";
}
.icon-gs-icon-directions:before {
  content: "\e90c";
}
.icon-gs-icon-email:before {
  content: "\e90d";
}
.icon-gs-icon-energy:before {
  content: "\e90e";
}
.icon-gs-icon-error:before {
  content: "\e90f";
}
.icon-gs-icon-eye-closed:before {
  content: "\e910";
}
.icon-gs-icon-eye-open:before {
  content: "\e911";
}
.icon-gs-icon-filters:before {
  content: "\e912";
}
.icon-gs-icon-heart-filled:before {
  content: "\e913";
}
.icon-gs-icon-heart-outlined:before {
  content: "\e914";
}
.icon-gs-icon-location:before {
  content: "\e915";
}
.icon-gs-icon-membership:before {
  content: "\e916";
}
.icon-gs-icon-my-account:before {
  content: "\e917";
}
.icon-gs-icon-password:before {
  content: "\e918";
}
.icon-gs-icon-phone:before {
  content: "\e919";
}
.icon-gs-icon-popup-check .path1:before {
  content: "\e91a";
  color: rgb(255, 255, 255);
}
.icon-gs-icon-popup-check .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(41, 47, 68);
}
.icon-gs-icon-qr-code:before {
  content: "\e91c";
}
.icon-gs-icon-search:before {
  content: "\e91e";
}
.icon-gs-icon-settings:before {
  content: "\e91f";
}
.icon-gs-icon-shower:before {
  content: "\e920";
}
.icon-gs-icon-time:before {
  content: "\e921";
}
.icon-gs-icon-water:before {
  content: "\e922";
}
.icon-gs-icon-language:before {
  content: "\e929";
}
.icon-gs-icon-communication:before {
  content: "\e928";
}
.icon-gs-icon-information:before {
  content: "\e932";
}
.icon-gs-icon-guest:before {
  content: "\e931";
}
.icon-gs-icon-add:before{
  content: "\e92f";
}
.icon-gs-icon-remove:before{
  content: "\e92e";
}
.icon-gs-icon-edit:before{
  content: "\e930";
}
