/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';
@import 'swiper/scss';
@import 'swiper/scss/pagination';
@import '@ionic/angular/css/ionic-swiper';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import 'assets/fonts/icon-fonts/icons.scss';

/* Font-family */
@import 'assets/fonts/montserrat/font-family.scss';

@import 'theme/button.scss';
@import 'theme/input-components.scss';
@import 'theme/toast.scss';
@import 'theme/modal.scss';
@import 'theme/field.scss';
@import 'theme/segment.scss';
@import 'theme/generic-components.scss';

/* Swiper */
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

ion-toolbar {
  --background: var(--header-background-color);
}

cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;

  .cdk-virtual-scroll-content-wrapper {
    max-width: 100%;
  }
}

.progress-container {
  display: flex;
  height: 100%;
  align-items: center;
}

.progress {
  height: 6px;
  width: 100%;
  background-color: var(--light-grey);
  margin-left: 40px;
  margin-right: 40px;
  border-radius: 15px;
  overflow: hidden;
  transform: translateZ(0);
}

.progress .progress-bar {
  background-color: var(--black);
  height: 6px;
  border-radius: 15px;
  animation: progress 2s infinite linear;
}

@keyframes progress {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

ion-spinner {
  &.spinner-primary {
    --color: var(--white);
  }
  &.spinner-secondary {
    --color: var(--primary-color-1);
  }
}

.ionic-element-white-font {
  --color: var(--white);
}
.ionic-element-black-font {
  --color: var(--black);
}

.white-text {
  color: var(--white);
}
.black-text {
  color: var(--black);
}

.gs-toggle {
  --handle-background: var(--toggle-off);
  --handle-background-checked: var(--toggle-on);
  --background: var(--toggle-off-background);
  --background-checked: var(--toggle-on-background);

  &.toggle-disabled {
    opacity: 1;
    --handle-background: var(--toggle-disabled);
    --handle-background-checked: var(--toggle-disabled);
    --background: var(--toggle-disabled-background);
    --background-checked: var(--toggle-disabled-background);
  }
}

.chip {
  --background: var(--activity-chip-background);
  --color: var(--font-color);
  padding: 12px 16px;
  margin: 0 1em 0.5em 0;
  height: auto;
  border-radius: 32px;
  font-weight: 600;
  font-size: 10px;
}
