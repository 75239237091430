ion-input .input-clear-icon.sc-ion-input-ios {
  margin-right: 20px;
}

.clear-input-no-margin .input-clear-icon.sc-ion-input-ios{
  margin-right: 0;
}

ion-searchbar input::placeholder {
	font-size: 14px;
}

ion-searchbar input {
  margin-left: 4px;
  font-size: 14px;
}

.highlight {
  font-weight: 600;
}
