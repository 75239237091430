ion-modal.confirmation-modal {
  --background: transparent;
  --border-radius: 30px;
  --backdrop-opacity: 0.8;

  &.bottom {
    align-items: flex-end;
  }
  --height: auto;
  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
        max-height: 80vh;
        overflow: auto;
        padding: 100px;
    }
  }
}

ion-modal::part(content) {
  width: 100%;
}

ion-modal.country-initials {
  --border-radius: 20px;
  ion-content::part(scroll) {
    bottom: calc(var(--offset-bottom)*(-1) + 88px);
  }
  &::part(handle) {
    height: 3px;
    margin-top: 24px;
    width: 48px;
    background-color: var(--light-grey-2);
  }
}

ion-modal.date-picker-modal{
  --height: auto;
  --border-radius: 20px;
  overflow-y: scroll;
}

ion-modal.language-picker-modal{
  --height: auto;
  --border-radius: 20px;
  overflow-y: scroll;
}

.dark-mode-modal{
  display: flex;
  --height: auto;
}

ion-modal.add-guests-modal{
  --height: 90%;
  --border-radius: 20px;
  overflow-y: scroll;
}

.error-modal{
  --border-radius: 20px;
  --height: auto;
  display: flex;
}
